import { default as $ } from 'jquery';

$(document).ready(function () {

    if ($(window).innerWidth() > 767) {
        if($('#product-tabs').hasClass('-alt')) {
            // Hide all sections but the first one, give first item active class
            $('.m-products-items-body').each(function() {
                $(this).children("div.m-products-item:not(:first)").removeClass('-visible');
            });
            
            $('.m-products-items-button').each(function() {
                $(this).children("div.m-products-item-section:first").addClass('-active');
            });
        
            $(".m-products-item-section").on("click", function(){
                var itemId = $(this).attr('id');
                $('.m-products-item-section').removeClass('-active');
                $(this).addClass('-active');
                $('.m-products-item').removeClass('-visible');
                $('.m-products-item.'+ itemId).addClass('-visible');
            });
        } else {
            // Hide all sections but the first one, give first item active class
            $('.m-products-items-body').each(function() {
                $(this).children("div.m-products-item:not(:first)").hide();
            });
            
            $('.m-products-items-button').each(function() {
                $(this).children("div.m-products-item-section:first").addClass('-active');
            });
        
            $(".m-products-item-section").on("click", function(){
                var itemId = $(this).attr('id');
                $('.m-products-item-section').removeClass('-active');
                $(this).addClass('-active');
                $('.m-products-item').hide();
                $('.m-products-item.'+ itemId).show();
            });
        };
     }
     else {
        // Mobile - open/close accordion
        $(".m-products-item-section.-mobile").click(function(){
         if($(this).closest(".m-products-item-container").hasClass("-hide")) {
            $('.m-products-item-section.-mobile').removeClass('-active');
            $(this).addClass('-active');
            $(".m-products-item-container").addClass("-hide");
            $(this).closest(".m-products-item-container").removeClass("-hide");
            $(".m-products-item-container").find(".m-products-item").slideUp();
            $(this).closest(".m-products-item-container").find(".m-products-item").slideDown();
            $(".fa-chevron-down").removeClass('-upsideDown');
            $(this).closest(".m-products-item-container").find(".fa-chevron-down").addClass('-upsideDown');
         }else{
            $('.m-products-item-section.-mobile').removeClass('-active');
            $(".m-products-item").addClass("-hide");
            $(".m-products-item-container").addClass("-hide");
            $(".m-products-item-container").find(".m-products-item").slideUp();
            $(".fa-chevron-down").removeClass('-upsideDown');
         }
       });
     }
});
