import { default as $, isEmptyObject } from 'jquery';

jQuery(document).ready(function () {
    var post_type = $(".post-type").data('type');
    
    if(!isEmptyObject(post_type)) {
        console.log(post_type);
        if(post_type === 'case_study') {
            $('[data-name="CaseStudies"]').addClass('-current');
        } else if(post_type === 'event') {
            $('[data-name="Events"]').addClass('-current');
        };
    };
});