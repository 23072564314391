import 'jquery';

jQuery(document).ready(function () {
    jQuery(document).on('click', ".item.search-able", function() {
        jQuery(".item.search form").submit();
    });

    jQuery(".item.search").click(function(e){
        jQuery(this).addClass("search-able");
        jQuery(".item.search input").focus();
        e.stopPropagation();
    });
});