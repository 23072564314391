import { default as $ } from 'jquery';
import 'slick-carousel';

$('.m-media-carousel-posts.-isCarousel').slick({
    dots: true,
    infinite: true,
    margin: 20,
    slidesToShow: 1,
    arrows: true,
    draggable: true,
    pauseOnHover: false,
    slidesToScroll: 1,
    controls: true,
    autoplay: false,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 1,
                arrows: false,

            }
        },
        {
            breakpoint: 767,

            settings: {
                slidesToShow: 1,
                arrows: false,
            }
        }
    ]
});
