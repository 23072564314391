import { default as $ } from 'jquery';

if($(window).width() >= 992){
// Search bar function

$('#faq').on('keyup', 'INPUT[type="search"]', function(event) {

    // get our elements
    let $faq = $(event.delegateTarget);
    
    // get the search filter value
    let search = $(this).val().replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    
    // get the repeater list in faq object
    let $repeater = $('.m-faq-question-container', $faq);
    
    // items visible counter
    let itemsVisible = 0;
  
    // return false early if we dont have a list
    if ($repeater.length < 1) return false;
  
    // loop through each item in the repeater list
    $($repeater).each(function(key, item) {
  
      // store our q and a text
      let text = $(this).text();

      // check if the item is visible using regular expression match
      let itemVisible = text.match(new RegExp(search, 'i'));
  
      // remove these classes
      $(item).removeClass('filter-visible filter-hidden');
  
      // check if we have a match
      $(item).addClass('filter-' + (itemVisible ? 'visible' : 'hidden'));
  
      // if item is visible, increment our count
      if (itemVisible) ++itemsVisible;
  
    });

    if (itemsVisible === 0) {
        $('.m-faq-not-found').removeClass('filter-hidden').show();
    } else {
        $('.m-faq-not-found').hide();
    } 
  
  });

// Hide all answers at load and auto select first category

$(document).ready(function(){
    $('.m-faq-answer').hide();
    $('.m-faq-questions').hide();

    $('.m-faq-categories').children(":first").addClass('-selected');

    if($('.m-faq-button.-selected').attr('id') == $('.m-faq-all-questions').children(":first").data('name')) {   
        $('.m-faq-all-questions').children(":first").show();
    };
});

// Change class on selected category and show relevant FAQs

$(".m-faq-button").on("click", function(){
    $('.m-faq-button').removeClass('-selected');
    $('.m-faq-question-container').removeClass('filter-hidden');
    $(this).addClass('-selected');
    var filtertag = $(this).attr('id');
    $('.m-faq-questions').hide().filter('.' + filtertag).show();
});

// Show and hide answers

$(".m-faq-content").on("click", function(){

    if($(this).hasClass("-selected")) {
        $(this).removeClass('-selected');
        $(this).find(".m-faq-answer").slideUp();
    } else {
        $('.m-faq-content').removeClass('-selected');
        $('.m-faq-answer').slideUp();
        $(this).addClass('-selected');
        $(this).find(".m-faq-answer").slideToggle();
     }
});

} else {


    $('#faq').on('keyup', 'INPUT[type="search"]', function(event) {

        // get our elements
        let $faq = $(event.delegateTarget);
        
        // get the search filter value
        let search = $(this).val().replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
        
        // get the repeater list in faq object
        let $repeater = $('.m-faq-mobile-question', $faq);
        
        // items visible counter
        let itemsVisible = 0;
      
        // return false early if we dont have a list
        if ($repeater.length < 1) return false;
      
        // loop through each item in the repeater list
        $($repeater).each(function(key, item) {
      
          // store our q and a text
          let text = $(this).text();
    
          // check if the item is visible using regular expression match
          let itemVisible = text.match(new RegExp(search, 'i'));
      
          // remove these classes
          $(item).removeClass('filter-visible filter-hidden');
      
          // check if we have a match
          $(item).addClass('filter-' + (itemVisible ? 'visible' : 'hidden'));
      
          // if item is visible, increment our count
          if (itemVisible) ++itemsVisible;
      
        });
    
        if (itemsVisible === 0) {
            $('.m-faq-not-found').removeClass('filter-hidden').show();
        } else {
            $('.m-faq-not-found').hide();
        } 
      
      });

    $(document).ready(function(){
        $('.m-faq-answer').hide();
        $('.m-faq-mobile-container').hide();
    });

    // Change class on selected category and show relevant FAQs

    $(".m-faq-button").on("click", function(){
        if($(this).hasClass("-selected")) {
            $(this).removeClass('-selected');
            $(this).closest(".m-faq-mobile-section").find(".m-faq-mobile-container").slideUp();
        } else {
            $('.m-faq-mobile-question').removeClass('filter-hidden');
            $('.m-faq-button').removeClass('-selected');
            $('.m-faq-mobile-container').slideUp();
            $(this).addClass('-selected');
            $(this).closest(".m-faq-mobile-section").find(".m-faq-mobile-container").slideToggle();
         }
    });

    $(".m-faq-mobile-question").on("click", function(){

        if($(this).hasClass("-selected")) {
            $(this).removeClass('-selected');
            $(this).find(".m-faq-answer").slideUp();
        }else {
            $('.m-faq-content').removeClass('-selected');
            $('.m-faq-answer').slideUp();
            $(this).addClass('-selected');
            $(this).find(".m-faq-answer").slideToggle();
         }
    });
};