import { default as $ } from 'jquery';
import 'slick-carousel';

$(function(){
  $('.m-reports-carousel.-isCarousel').slick({
      dots: false,
      infinite: false,
      margin: 20,
      slidesToShow: 4,
      arrows: true,
      draggable: true,
      pauseOnHover: false,
      slidesToScroll: 1,
      controls: true,
      autoplay: false,
      autoplaySpeed: 4000,
      adaptiveHeight: false,
      rows: 0,
      responsive: [
          {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
                arrows: true,
            }
          },
          {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                arrows: true,
            }
          },
          {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.5,
                arrows: true,
            }
          },
          {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                arrows: true,
            }
          }
      ]
  });
    
    // Slick filter
    $('.m-reports-form select').on('change', function() {
      var filterClass = getFilterValue();
      $('.m-reports-carousel.-isCarousel').slick('slickUnfilter');
      $('.m-reports-carousel.-isCarousel').slick('slickFilter', filterClass);
    });

    function getFilterValue() {
      // Grab all the values from the filters.
      var values = $('.m-report-form-group').map(function() {
        // For each group, get the select values.
        var groupVal = $(this).find('select').map(function() {
          return $(this).val();
        }).get();
        // join the values together.
        return groupVal.join('');
      }).get();
      // Remove empty strings from the filter array.
      // and join together with a comma. this way you 
      // can use multiple filters.
      return values.filter(function(n) {
        return n !== "";
      }).join(',');
    }
    
});