import 'jquery';
import { default as $ } from 'jquery';

jQuery(document).ready(function () {
    if (jQuery(window).width() < 768) {
        var header = jQuery("#header");
        jQuery(window).scroll(function() {
            var scroll = jQuery(window).scrollTop();
    
            if (scroll >= 50) {
                header.addClass("-dark");
            } else {
                header.removeClass("-dark");
            }
        });    
        
    } else {
        
        var header = jQuery("#header");
        jQuery(window).scroll(function() {
            var scroll = jQuery(window).scrollTop();
    
            if (scroll >= 100) {
                header.addClass("-dark");
            } else {
                header.removeClass("-dark");
            }
        });    
    }
});

$('#noticeCloseButton').on('click', function(){
    $('#orangeNotice').slideUp();
});

jQuery(document).ready(function () {
    $('#mobileSearch').hide();
    $("#noticeBody").hide();
});

$('#mobileDropdownSearch').on('click', function(){
    $('#mobileSearch').slideToggle();
});

$('#moreInfoButton').on('click', function(){
    $("#noticeBody").slideToggle();
    $("#orangeNotice").toggleClass('-open');
    $('#moreInfoButton').toggleClass('-up');

    if($('#moreInfoButton').hasClass('-up')) {
        $("#moreInfoButton").html('Close<i class="fa-solid fa-chevron-down"></i>');
    } else {
        $("#moreInfoButton").html('Find out more<i class="fa-solid fa-chevron-down"></i>');
    };
});

$('#noticeCloseButton').on('click', function(){
    $("#orangeNotice").slideUp();
});