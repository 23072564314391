import { default as $ } from 'jquery';
import 'slick-carousel';

$("#reportFilter li").on("click", function(){
    var filtertag = $(this).attr('id');
    $('.m-reports-product').hide().filter('.' + filtertag).show();
});

$('.m-product-main-images-gallery.-isCarousel').slick({
    dots: false,
    infinite: false,
    slidesToShow: 4,
    arrows: true,
    draggable: true,
    pauseOnHover: false,
    slidesToScroll: 1,
    controls: true,
    autoplay: false,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                arrows: true,
            }
        },
        {
            breakpoint: 767,

            settings: {
                slidesToShow: 4,
                arrows: true,
            }
        }
    ]
});

$(document).ready(function () {
    $('.m-product-main-images-image').on("click", function(){
        var imageSrc = $(this).attr("src");    
        $(this).closest(".m-product-main-images").find(".m-product-main-images-focus-image").attr("src", imageSrc);
    });

    var dropdown = $('#dropdownValue').text();

    if(dropdown == 1){
        $('#product_options').on('change', 'input[type="radio"][name="radio_buttons"]', function(){
            if ( $('#radio-Commercial').is(':checked')) {
                $('.m-product-main-dropdown-option.-commercial').removeClass('-hidden');
                $('.m-product-main-dropdown-option.-residential').addClass('-hidden');
                $('.m-product-main-dropdown-option.-other').addClass('-hidden');
            } else if ( $('#radio-Residential').is(':checked')) {
                $('.m-product-main-dropdown-option.-residential').removeClass('-hidden');
                $('.m-product-main-dropdown-option.-commercial').addClass('-hidden');
                $('.m-product-main-dropdown-option.-other').addClass('-hidden');
            } else {
                $('.m-product-main-dropdown-option.-residential').removeClass('-hidden');
                $('.m-product-main-dropdown-option.-commercial').removeClass('-hidden');
                $('.m-product-main-dropdown-option.-other').removeClass('-hidden');
            };
        });
    } else {
        $('#product_options').on('change', 'input[type="radio"][name="radio_buttons"]', function(){
            if ( $('#radio-Commercial').is(':checked')) {
                $('.m-product-main-links.commercial').removeClass('-hidden');
                $('.m-product-main-links.residential').addClass('-hidden');
            } else if ( $('#radio-Residential').is(':checked')) {
                $('.m-product-main-links.residential').removeClass('-hidden');
                $('.m-product-main-links.commercial').addClass('-hidden');
            };
        });
    };

    $("#product_dropdown").change(function () {
        var $id = $(this).val();
        $('.m-product-main-links').addClass('-hidden');
        $('#' + $id + '-links').removeClass('-hidden');
    });

    if($(".m-product-main-icons-container").length <= 2) {
        $(".m-product-main-icons-container").addClass("widthChange");
    } else {
        $(".m-product-main-icons-container").removeClass("widthChange");
    };
});