import { default as $ } from 'jquery';
import 'slick-carousel';

$('.m-spotlight-carousel.-isCarousel').slick({
    dots: false,
    infinite: false,
    margin: '20px',
    slidesToShow: 6,
    arrows: true,
    draggable: true,
    prevArrow: '<button type="button" class="slick-prev"> <i class="fa-solid fa-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fa-solid fa-chevron-right"></i></button>',
    appendArrows: '.m-spotlight-arrows',
    pauseOnHover: false,
    slidesToScroll: 1,
    controls: true,
    autoplay: false,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 4,
                arrows: true,
            }
        },

        {
            breakpoint: 980,
            settings: {
                slidesToShow: 2.5,
                arrows: false,
            }
        },
        {
            breakpoint: 767,

            settings: {
                slidesToShow: 2,
                arrows: false,
                centerMode: false,
            }
        },
        {
            breakpoint: 576,

            settings: {
                slidesToShow: 1.5,
                arrows: false,
                centerMode: false,
            }
        }
    ]
});
