import { default as $ } from 'jquery';
import 'slick-carousel';

$('.m-partners-carousel.-isCarousel').slick({
    infinite: true,
    arrows: false,
    draggable: true,
    pauseOnHover: false,
    controls: true,
    autoplay: false,
    autoplaySpeed: 4000,
    centerMode: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                variableWidth: false,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                arrows: false,
                variableWidth: false,
            }
        }
    ]
});

$('.m-partners-mobile-carousel.-isCarousel').slick({
    dots: true,
    infinite: true,
    margin: 20,
    slidesToShow: 1,
    arrows: false,
    draggable: true,
    pauseOnHover: false,
    slidesToScroll: 1,
    controls: true,
    autoplay: false,
    autoplaySpeed: 4000,
    centerMode: true,
    centerPadding: '20px',
    rows: 0,
    responsive: [
        {
            breakpoint: 980,
            settings: {
                slidesToShow: 1,
                arrows: false,
            }
        },
        {
            breakpoint: 767,

            settings: {
                slidesToShow: 1,
                arrows: false,
            }
        }
    ]
});

var partnersAutoplay = false;

// Function to start autoplay when container is in view
function autoPlayPartners() {
    var container = $('.m-partners-carousel');

    if (container.length === 0 || partnersAutoplay) {
        return;
    }

    var containerTop = container.offset().top;
    var containerBottom = containerTop + container.height();
    var windowTop = $(window).scrollTop();
    var windowBottom = windowTop + $(window).height();

    if (containerBottom > windowTop && containerTop < windowBottom) {
        // The container is in view
        container.slick('slickPlay');
        partnersAutoplay = true;
    }
}

if ($('.m-news-articles').length) {
    // Check if the container is in view when the page loads
    autoPlayPartners();
    
    // Listen for scroll events to check if the container comes into view
    $(window).scroll(function () {
        autoPlayPartners();
    });
};
