import { default as $ } from 'jquery';
import 'slick-carousel';

$('.m-news-articles.-isCarousel').slick({
    dots: false,
    infinite: true,
    margin: 20,
    slidesToShow: 3,
    arrows: true,
    draggable: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    controls: true,
    autoplay: false,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 980,
            settings: {
                slidesToShow: 2,
                arrows: false,

            }
        },
        {
            breakpoint: 767,

            settings: {
                slidesToShow: 1.5,
                arrows: false,
            }
        },
        {
            breakpoint: 576,

            settings: {
                slidesToShow: 1,
                arrows: false,
            }
        }
    ]
});

var autoplayStarted = false;

// Function to start autoplay when container is in view
function startAutoplayWhenInView() {
    var container = $('.m-news-articles');

    if (!container.length || autoplayStarted) {
        return; // Exit the function if the container is not found
    }

    var containerTop = container.offset().top;
    var containerBottom = containerTop + container.height();
    var windowTop = $(window).scrollTop();
    var windowBottom = windowTop + $(window).height();

    if (containerBottom > windowTop && containerTop < windowBottom) {
        // The container is in view
        container.slick('slickPlay');
        autoplayStarted = true;
    }
}

if ($('.m-news-articles').length) {
    // Check if the container is in view when the page loads
    startAutoplayWhenInView();

    // Listen for scroll events to check if the container comes into view
    $(window).scroll(function () {
        startAutoplayWhenInView();  
    });
};