import { default as $ } from 'jquery';

// Show and hide search icon on search bar when it has a value

$(".m-faq-nav-search").on("input", function(){
    $(".m-faq-nav-search-label").hide();
});

$('.m-faq-nav-search').blur(function(){
    if( !$(this).val() ) {
        $(".m-faq-nav-search-label").show();
    }
});