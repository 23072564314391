import { default as $ } from 'jquery';
import 'slick-carousel';

$('.m-card-carousel.-isCarousel').slick({
    dots: false,
    infinite: false,
    margin: 20,
    slidesToShow: 3,
    arrows: true,
    draggable: true,
    pauseOnHover: false,
    slidesToScroll: 1,
    controls: true,
    autoplay: false,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                arrows: true,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1.2,
                arrows: true,
            }
        }
    ]
});
