// This file contains all parachute imports, e.g:
// import './parachute/foo/foo.js';
// import './parachute/bar/bar.js';

import './partners/partners';
import './mmenu/mmenu';
import './search/search';
import './header/header';
import './modals/modals';
import './media-text/media-text';
import './animation/animation';
import './faq/faq';
import './news/news';
import './products-services/products-services';
import './spotlight/spotlight';
import './carousel/carousel';
import './card-carousel/card-carousel';
import './media-carousel/media-carousel';
import './team/team';
import './reports/reports';
import './product/product';
import './media-text/media-text-accordion';
import './faq/faq-search';
import './nav/nav-block';
import './resources/resources';

// Publicly accessible methods for this build, useful for callbacks in script tags etc.
// Try and keep most things inside this webpack bundle though
import { publicExports } from './public-exports';
window._Parachute = publicExports || {};
