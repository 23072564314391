import { default as $ } from 'jquery';

$(".m-media-accordion-item-heading").on("click", function(){
    if ($(this).closest(".m-media-accordion-item").hasClass("-noShow")) {
        $(".m-media-accordion-item-content").slideUp();
        $(".m-media-accordion-item").addClass("-noShow");
        $(".m-media-accordion-item-icon").removeClass('-upsideDown');
        $(this).closest(".m-media-accordion-item").removeClass("-noShow");
        $(this).closest(".m-media-accordion-item").find(".m-media-accordion-item-content").slideToggle();
        $(this).closest(".m-media-accordion-item").find(".m-media-accordion-item-icon").toggleClass("-upsideDown");
     } else {
        $(this).closest(".m-media-accordion-item").addClass("-noShow");
        $(".m-media-accordion-item-content").slideUp();
        $(".m-media-accordion-item-icon").removeClass("-upsideDown");
     };
});
