import 'jquery';
import 'mmenu-js';

document.addEventListener(
    "DOMContentLoaded", () => {
        const menu = new Mmenu("#mobileMenu", {

        },
        {
            openingInterval: 0,
            transitionDuration: 0,
            position: "left-front",
            offCanvas: {
                blockUI: false,
                clone: false,
                page: {
                    selector: "#page",
                }
            }
        });
    
        const api = menu.API;

        jQuery("#mobileDropdownButton").click(function (event) {
            if (!jQuery(this).hasClass("-active")) {
                api.open();
                jQuery('body, #header').addClass('-navActive');
                jQuery(this).addClass("-active");
            }
            else{
                jQuery('body, #header').removeClass('-navActive');
                jQuery(this).removeClass('-active');
                api.close();
            }
        });

        jQuery('#mobileDropdownButton').click( function () {
            jQuery('body, #header').removeClass('-navActive');
            api.close();
        });

        api.bind("close:start", function () {
            jQuery("#mobileDropdownButton").removeClass("-active");
        });
    
    }
);

// document.addEventListener(
//     "DOMContentLoaded", () => {
//         const menu = new Mmenu("#mobileMenu", {

//         },
//         {
//             openingInterval: 0,
//             transitionDuration: 0,
//             offCanvas: {
//                 blockUI: false,
//                 clone: false,
//                     page: {
//                         selector: "#page"
//                     }
//             }
//         });
        

//         const api = menu.API;

//         jQuery("#mobileDropdownButton").click(function (event) {
//             if (!jQuery(this).hasClass("-active")) {
//                 api.open();
//                 jQuery('body, #header').addClass('-navActive');
//                 jQuery(this).addClass("-active");
//             }
//             else{
//                 jQuery('body, #header').removeClass('-navActive');
//                 jQuery(this).removeClass('-active');
//             }
//         });

//         jQuery('.mobileNavClose').click( function () {
//             jQuery('body, #header').removeClass('-navActive');
//             api.close();
//         });

//         api.bind("close:start", function () {
//             jQuery("#mobileDropdownButton").removeClass("-active");
//         });
//     }
// );