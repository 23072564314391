import { default as $ } from 'jquery';
import 'slick-carousel';

$('.m-team-modal-gallery.-isCarousel').slick({
    dots: false,
    infinite: false,
    margin: 20,
    slidesToShow: 4,
    arrows: false,
    draggable: true,
    pauseOnHover: false,
    slidesToScroll: 1,
    controls: true,
    autoplay: false,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 3,
                arrows: false,
                draggable: true,
            }
        },
        {
            breakpoint: 767,

            settings: {
                slidesToShow: 3,
                arrows: false,
                draggable: true,
            }
        }
    ]
});

$(".m-team-member-button").on("click", function(){
    var buttonTarget = $(this).attr('data-bs-target');
    $(buttonTarget).find('.slick-slider').slick('refresh');

});

$(".m-team-modal-gallery").on("click", '.m-team-modal-image', function(){
    var imageSrc = $(this).attr("src");    
    $(this).closest(".m-team-modal-images").find(".m-team-modal-main-image").attr("src", imageSrc);
});
    